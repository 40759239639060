import axios from "axios";
import { ErrorMsg, ShowLoading, HideLoading } from "./Utils";
import history from "../../shared/history";
export default class Controller {
  constructor() {
    this.baseURL = "";
    this.axios = null;
    this.developer = true;
    this.getAxios();
  }

  showLoading = true;

  getAxios() {
    const self = this;
    if (window.location.href.includes("localhost")) {
      this.baseURL = "http://localhost:8082";
    } else {
      this.baseURL = "/api";
    }

    this.axios = axios.create({
      baseURL: this.baseURL
    });

    this.axios.interceptors.request.use(async function(config) {
      // const token = await self.getToken("kivervinicius@gmail.com", "senha123");
      const valid = await self.verifyToken();
      if (!valid) {
        history.replace("/login");
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        HideLoading();
        return;
      }
      const token = localStorage.getItem("token");
      if (token != null) {
        config.headers["jwt-token"] = token;
      }
      return config;
    });
  }

  async getToken(mail, pass, usemd5 = true) {
    // tratar para armazenar o token
    const res = await axios.get(`${this.baseURL}/auth/token/`, {
      params: {
        mail,
        pass,
        admin: true,
        usemd5
      }
    });
    return res.data;
  }

  async verifyToken() {
    const res = await axios.get(`${this.baseURL}/auth/verify/`, {
      headers: {
        "jwt-token": localStorage.getItem("token") || ""
      }
    });
    if (res.data && res.data.status !== 401 && res.data["jwt-token"]) {
      localStorage.setItem("token", res.data["jwt-token"]);
      console.warn("token refreshed");
      return true;
    }
    return false;
  }

  async get(url, params = {}) {
    if (this.showLoading) {
      ShowLoading("Aguarde, carregando...");
    }
    const result = await this.axios.get(url, {
      params
    });

    if (result.status === 200) {
      if (this.showLoading) {
        HideLoading();
      }
      return result.data;
    }
    ErrorMsg(result.statusText || "Não foi possível conectar na url " + url);
  }

  async post(url, content) {
    try {
      if (this.showLoading) {
        ShowLoading("Aguarde, carregando...");
      }
      const result = await this.axios.post(url, content);
      if (result.status === 200) {
        if (this.showLoading) {
          HideLoading();
        }
        if (result.data.status) {
          ErrorMsg(result.data.message);
          return "";
        }
        return result.data;
      } else {
        ErrorMsg(result.statusText);
        return "";
      }
    } catch (err) {
      ErrorMsg(err);
    }
  }
}
