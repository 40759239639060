import React from "react";
import { Button } from "reactstrap";
// import { logout } from "../../../auth/";

const Header = props => (
  <header>
    <nav className="navbar navbar-expand navbar-dark bg-blue static-top d-print-none">
      <a className="navbar-brand mr-1" href="/">
        AppCE
      </a>
      {props.authed && (
        <span className="logout">
          <Button
            color="danger"
            onClick={() => {
              localStorage.removeItem("token");
              localStorage.removeItem("user");
              window.location.reload(true);
            }}
          >
            Sair
          </Button>
        </span>
      )}
    </nav>
  </header>
);

export default Header;
