import CrudController from "../shared/CrudController";
import { ErrorMsg } from "../shared/Utils";
import history from "../../shared/history";

export default class PermissoesController extends CrudController {
  constructor() {
    super("usuarioPermissoes");
    this.showLoading = false;
  }

  usuarioLogado = () => {
    let result = {
      id: ""
    };
    try {
      result = JSON.parse(localStorage.getItem("user"));
    } catch (e) {
      localStorage.removeItem("user");
    }
    return result;
  };

  async listar(usuarioId) {
    return await this.get(`/${this.path}/listar`, {
      usuarioId
    });
  }

  async carregaPermissao() {
    const usuario = this.usuarioLogado();
    if (!usuario) {
      history.push("/login");
      ErrorMsg("Não foi possível verificar, faça login novamente.");
      return;
    }
    return await this.get(`/${this.path}/carregar`, {
      usuarioId: usuario.id
    });
  }

  async criar(permissaoId, usuarioId) {
    return await this.post(`/${this.path}/criar`, {
      usuarioId,
      permissaoId
    });
  }

  async criarTudo(usuarioId) {
    return await this.post(`/${this.path}/criarTudo`, {
      usuarioId
    });
  }

  async excluir(id) {
    return await this.get(`/${this.path}/excluir`, {
      id
    });
  }

  async excluirTudo(usuarioId) {
    return await this.post(`/${this.path}/excluirTudo`, {
      usuarioId
    });
  }

  // async getPermission(usuarioId, acao) {
  // select * from permissoes as p
  // left join usuarios_permissoes up on (p.id = up."permissaoId")
  // where p.menu_sis_id = 2 and up."usuarioId" = 2 and acao = 'SHOW'
  // }
}
