import Swal from "sweetalert2";

export async function ErrorMsg(html) {
  return Swal({
    title: "Erro!",
    type: "error",
    html
  });
}

export async function SuccessMsg(html) {
  return Swal({
    type: "success",
    html
  });
}

export async function QuestionMsg(html) {
  const result = await Swal({
    type: "question",
    html,
    showCancelButton: true
  });
  return result.value;
}

export async function ShowLoading(html) {
  Swal({
    html
  });
  Swal.showLoading();
}

export async function HideLoading() {
  Swal.close();
}

export function AbreviarNome(name) {
  if (!name) return "";
  let splitName = name.split(" ");
  if (splitName.length >= 3) {
    for (let i = 1; i < splitName.length - 1; i++) {
      if (splitName[i].length > 2) {
        splitName[i] = splitName[i].charAt(0) + ".";
      }
    }
  }
  return splitName.join(" ");
}

export function StatusPedido(nStatus){
  switch (nStatus) {
    case 1: return "Novo";
    case 2: return "Aguardando confirmação"; 
    case 3: return "Concluído"; 
    case 4: return "Cancelado"; 
    default: return "Situação não cadastrado";
  }
}

