import React from "react";

function Suspense (props) {
  return (
    <React.Suspense fallback={<div>Carregando</div>}>
      {props.children}
    </React.Suspense>
  );
}

export default Suspense;
