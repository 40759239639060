export default Object.freeze({
  produtos: "Produtos",
  promocoes: "Promoções",
  notificacoes: "Notificações",
  atendimento: "Atendimento",
  departamentos: "Departamentos",
  usuarios: "Usuários",
  sorteios: "Sorteios",
  delivery: "Delivery",
});
