import React from "react";
import { Nav, NavItem } from "reactstrap";
import { Link } from "react-router-dom";
import {
  FaBox,
  FaDollarSign,
  FaBell,
  FaComments,
  FaArchive,
  FaDrawPolygon,
  FaUser,
  FaShippingFast, 
} from "react-icons/fa";
import Permissao from "components/Permissao";
import messages from "config/messages";
import Const from "config/Const";

const Sidebar = () => (
  <Nav className="sidebar d-print-none" navbar>
    <NavItem>
      <Permissao menu={1} acao={Const.CRUD.SHOW}>
        <Link to="/departamentos" className="nav-link">
          <FaArchive /> &nbsp;{messages.departamentos}
        </Link>
      </Permissao>

      <Permissao menu={2} acao={Const.CRUD.SHOW}>
        <Link to="/produtos" className="nav-link">
          <FaBox /> &nbsp;{messages.produtos}
        </Link>
      </Permissao>

      <Permissao menu={3} acao={Const.CRUD.SHOW}>
        <Link to="/promocoes" className="nav-link">
          <FaDollarSign /> &nbsp;{messages.promocoes}
        </Link>
      </Permissao>

      <Permissao menu={4} acao={Const.CRUD.SHOW}>
        <Link to="/notificacoes" className="nav-link">
          <FaBell /> &nbsp;{messages.notificacoes}
        </Link>
      </Permissao>

      <Permissao menu={5} acao={Const.CRUD.SHOW}>
        <Link to="/mensagens" className="nav-link">
          <FaComments /> &nbsp;{messages.atendimento}
        </Link>
      </Permissao>

      <Permissao menu={6} acao={Const.CRUD.SHOW}>
        <Link to="/sorteios" className="nav-link">
          <FaDrawPolygon /> &nbsp;{messages.sorteios}
        </Link>
      </Permissao>

      <Permissao menu={7} acao={Const.CRUD.SHOW}>
        <Link to="/usuarios" className="nav-link">
          <FaUser /> &nbsp;{messages.usuarios}
        </Link>
      </Permissao>

      <Permissao menu={7} acao={Const.CRUD.SHOW}>
        <Link to="/delivery" className="nav-link">
          <FaShippingFast /> &nbsp;{messages.delivery}
        </Link>
      </Permissao>      

    </NavItem>
  </Nav>
);

export default Sidebar;
