import React, { PureComponent } from "react";
import { Container } from "reactstrap";
import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import Proptypes from "prop-types";

class Dashboard extends PureComponent {
  render() {
    return (
      <>
        <Header authed={this.props.authed}/>
        <div id="wrapper">
          {this.props.authed && (<Sidebar />)}
          
          <div id="content-wrapper">
            <Container fluid>
              {this.props.children}
            </Container>
          </div>
        </div>
      </>
    );
  }
}

Dashboard.propTypes = {
  authed: Proptypes.bool,
}

export default Dashboard;
