import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Suspense from "./shared/Suspense";
import Dashboard from "./dashboard";
import PermissoesController from "./permissoes/PermissoesController";
import logo from "../img/appce-logo.png";
import "./App.scss";
// const Dashboard  = React.lazy(() => import("./dashboard"));
const Login = React.lazy(() => import("./login"));
const Home = React.lazy(() => import("./home"));
const Produtos = React.lazy(() => import("./produtos/"));
const Promocoes = React.lazy(() => import("./promocoes"));
const Notificacoes = React.lazy(() => import("./notificacoes"));
const Mensagens = React.lazy(() => import("./mensagens"));
const Departamentos = React.lazy(() => import("./departamentos"));
const Sorteios = React.lazy(() => import("./sorteios"));
const Usuarios = React.lazy(() => import("./usuarios"));
const Permissoes = React.lazy(() => import("./permissoes"));

const Delivery = React.lazy(() => import("./delivery"));

function PrivateRoute({ component: Component, authed, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        authed === true ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
}

function Loading() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100vw",
        height: "100vh"
      }}
    >
      <div>
        <img alt="Carregando" src={logo} />
        <h5>Carregando...</h5>
      </div>
    </div>
  );
}

class App extends Component {
  constructor(props) {
    super(props);
    this.controller = new PermissoesController();
  }
  state = {
    authed: false,
    loading: true
  };

  checkToken = null;
  check = () => {
    if (localStorage.getItem("token")) {
      this.setState({
        authed: true,
        loading: false
      });
    } else {
      this.setState({
        authed: false,
        loading: false
      });
    }
  };
  componentDidMount() {
    this.checkToken = setInterval(() => {
      this.check();
    }, 60 * 1000);

    const permissao = sessionStorage.getItem("permissao");

    const carrega = () => {
      this.controller.carregaPermissao().then(data => {
        this.check();
        debugger;
        if (data) {
          sessionStorage.setItem("permissao", JSON.stringify(data));
        }
      });
    };

    try {
      if (!permissao) {
        carrega();
        this.check();
      } else {
        this.check();
      }
    } catch (e) {
      carrega();
    }
  }

  componentWillUnmount() {
    if (this.checkToken) {
      clearInterval(this.checkToken);
    }
  }

  render() {
    const { authed } = this.state;
    return this.state.loading ? (
      <Loading />
    ) : (
      <Dashboard authed={authed}>
        <Suspense>
          <Switch>
            <Route path="/login" component={Login} />
            <PrivateRoute exact path="/" authed={authed} component={Home} />
            <PrivateRoute authed={authed} path="/home" component={Home} />
            <PrivateRoute
              authed={authed}
              path="/produtos"
              component={Produtos}
            />
            <PrivateRoute
              authed={authed}
              path="/promocoes"
              component={Promocoes}
            />
            <PrivateRoute
              authed={authed}
              path="/notificacoes"
              component={Notificacoes}
            />
            <PrivateRoute
              authed={authed}
              path="/mensagens"
              component={Mensagens}
            />
            <PrivateRoute
              authed={authed}
              path="/departamentos"
              component={Departamentos}
            />
            <PrivateRoute
              authed={authed}
              path="/delivery"
              component={Delivery}
            /> 
            <PrivateRoute
              authed={authed}
              path="/sorteios"
              component={Sorteios}
            />
            <PrivateRoute
              authed={authed}
              path="/usuarios"
              component={Usuarios}
            />
            <PrivateRoute
              authed={authed}
              path="/permissoes"
              component={Permissoes}
            />
          </Switch>
        </Suspense>
      </Dashboard>
    );
  }
}

export default App;
