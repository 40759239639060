/* eslint-disable eqeqeq */
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import PermissoesController from "../../view/permissoes/PermissoesController";
import { ErrorMsg } from "../../view/shared/Utils";
import history from "../../shared/history";

class Permissao extends PureComponent {
  componentDidMount() {
    this.controller = new PermissoesController();
    this.carrega().then(() => {
      this.setState({ temPermissao: this.permite() }, () => {
        if (!this.state.temPermissao && this.props.showMessage) {
          this.props.onDenied.call();
        }
      });
    });
  }

  state = {
    listPermissao: [],
    temPermissao: false
  };

  carrega = () => {
    return new Promise((resolve, reject) => {
      const list = sessionStorage.getItem("permissao");
      try {
        this.setState(
          {
            listPermissao: JSON.parse(list)
          },
          resolve
        );
      } catch (e) {
        this.setState(
          {
            listPermissao: []
          },
          resolve
        );
      }
    });
  };

  permite = () => {
    if (!this.state.listPermissao || this.state.listPermissao.length === 0) {
      this.carrega();
      return false;
    }
    const tem =
      this.state.listPermissao.filter(row => {
        const sim =
          row.menuid == this.props.menu &&
          row.acao == this.props.acao &&
          row.tempermissao;
        return sim;
      }).length > 0;
    return tem;
  };

  render() {
    return <span>{this.state.temPermissao && this.props.children}</span>;
    // return <span>{this.props.children}</span>;
  }
}

Permissao.propTypes = {
  children: PropTypes.any.isRequired,
  menu: PropTypes.number.isRequired,
  acao: PropTypes.string.isRequired,
  onDenied: PropTypes.func,
  showMessage: PropTypes.bool
};

Permissao.defaultProps = {
  onDenied: () => {
    history.replace("/");
    setTimeout(() => {
      ErrorMsg("Usuário sem permissão para este recurso.");
    }, 250);
  },
  showMessage: false
};

export default Permissao;
