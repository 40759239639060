import Controller from "./Controller";

export default class CrudController extends Controller {
  constructor (path) {
    super();
    this.path = path;
  }

  async listar() {
    return await this.get(`/${this.path}/listar`);
  }

  async obter(id) {
    return await this.get(`/${this.path}/obter/`, { id });
  }

  async gravar(instance) {
    return await this.post(`/${this.path}/gravar/`, instance);
  }

  async excluir(id) {
    return await this.get(`/${this.path}/excluir/`, { id });
  }
}
